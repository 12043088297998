.navbar{
  // background-color: rgba(0, 0, 0, 0.5);
  background: linear-gradient(-180deg, #ac32e4, #7918f2, #4801ff);
}
.nav-logo {
  width: 120px;
  height: 50px;
}
.nav-links{
  padding: 1rem;
  display: none;
}
.nav-link {
  display: block;
  color: white;
  text-decoration: none;
  font-weight: bold;
  letter-spacing:var(--mainSpacing);
  transition: var(--mainTransition);
  font-size: 15.5px;
  font-family: var(--mainFont);
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
}
.nav-link:hover {
  color: var(--primaryColor);
  cursor: pointer;
}
.nav-header {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-contact{
  display: none
}
.nav-icons{
  display: flex;
  justify-content: center;
}
.navbar-social-icon{
  font-size: 20px;
  color: var(--primaryColor);
}
.navbar-social-icon:nth-child(1){
  margin-right: 10px;
}
.navbar-social-icon :hover{
  color: white;
}
.navbar-number{
  color: white;
  font-size: 14px;
  margin: 0;
}
// .find{
//   color: var(--mainWhite);
//   font-weight: bold;
// }
// .find input{
//   padding: 4px 10px;
//   margin-left: 8px;
// }
// .search-icon{
//   background-color: var(--primaryColor);
//   color: var(--mainBlack);
//   font-size: 18px;
//   padding: 3.5px;
//   margin-left: 5px;
//   margin-bottom: -5.8px;
//   cursor: pointer;
//   -webkit-transform: scaleX(-1);
//   transform: scaleX(-1);
// }
.nav-toggle {
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.5rem;
  color: var(--primaryColor);
  cursor: pointer;
  transition: var(--mainTransition);
}
.nav-toggle:hover {
  transform: scale(1.2);
}
@media screen and (min-width: 992px) {
  .navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: var(--mainPadding);
  }
  .nav-toggle {
    display: none;
  }
  .nav-links {
    display: flex;
  }
  .nav-header {
    padding: 0;
  }
  .nav-link {
    padding: 0;
    margin-right: 30px;
    margin-bottom: 0;
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }
  .nav-contact{
    display: block;
  }
}

.dropdown {
  display: block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color:var(--mainWhite);
  width: 170px;
  z-index: 500;
  border-radius: 3px;
}

.dropdown-link {
  color: var(--mainBlack);
  padding: 10px;
  text-decoration: none;
  display: block;
  font-weight: bold;
  font-size: 15.5px;
}

.dropdown-link:hover {
  background-color: var(--primaryColor);
}

.dropdown:hover .dropdown-content {
  display: block;
}
.down-icon{
  margin-left: 3px;
}
.dropdown-side{
  position: relative;
}
.side-link{
  display: none;
  background-color: var(--mainWhite);
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  position: absolute;
  left: 100%;
  top: 0;
  width: 130px;
}

.dropdown-side:hover .side-link {
  display: block;
  flex-wrap: nowrap;
}
.right-icon{
  position: absolute;
  left: 85%;
  top: 28%;
}