.unisell-banner{
    height: 250px;
    max-width: 950px;
    margin: 0 auto 70px auto;
    cursor: pointer;
    box-shadow: 0px 1px 11px -1px rgba(0,0,0,0.74);
}
.unisell-banner img{
    width: 100%;
    height: 100%;
    /* background-size: 100px 30px; */
}
@media screen and (max-width: 415px){
    .unisell-banner{
        height: 100px;
    }
}