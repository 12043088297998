.more{
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 650px;
  height: 410px;
  position: fixed;
  z-index: 7;
  background-color: var(--mainWhite);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.more-img{
  margin-right: 10px;
  width: 70%;
  border: 1px solid var(--mainGray);
}
.more-big-img{
  height: 100%;
  width: 100%;
}
.more-info{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}
.more-info-name{
  font-family: var(--mainFont);
  letter-spacing: var(--mainSpacing);
  margin: 0;
  font-size: 25px;
}
.more-info-price{
  font-size: 31px;
  font-family: var(--mainFont);
  color: orange;
  letter-spacing: var(--mainSpacing);
  position: relative;
  display: inline-block;
}
.more-info table{
  font-family: var(--mainFont);
  height: 60px;
  /* margin: 30px 0; */
}
.more-info table .name{
  font-weight: bold;
}
.more-close{
  position: absolute;
  font-size: 31px;
  color: var(--mainBlack);
  left: 95%;
  top: 1%;
  cursor: pointer;
  transition: var(--mainTransition);
}
.more-close :hover{
  transform: scale(0.9);
  color: var(--primaryColor);
}
.more-contact {
  display: flex;
  flex-direction: column;
  border: 1px solid #d4d4d4;
  max-width: 100%;
  height: 45%;
  display: block;
}
.more-contact-knowledge{
  margin-top: 13px;
  font-weight: bold;
  text-align: center;
}
.more-contact-icons{
  color: orange;
  font-size: 20px;
  margin-right: 13px;
}
/* .img-zoom-result {
  border: 1px solid #d4d4d4;
  width: 100%;
  height: 50%;
}
.img-zoom-lens {
  position: absolute;
  border: 1px solid #d4d4d4;
  width: 50px;
  height: 50px;
} */
.more-info-icon{
  position: absolute;
  color: var(--mainBlack);
  font-size: 16px;
  top: 90%;
  left: 100%;
}
@media screen and (max-width: 670px) {
  .more{
    width: 280px;
    height: 190px;
    padding: 0.5rem;
  }
  .more-info-name{
    font-size: 14px;
  }
  .more-close{
    font-size: 21px;
    left: 93%;
    top: 1%;
  }
  .more-info table td{
    font-size: 10px;
  }
  .more-contact-knowledge{
    font-size: 8.5px;
    font-weight: normal;
    margin-top: 4px;
  }
  .more-contact-icons{
    font-size: 10px;
    margin-right: 4px;
  }
}