
.hero-img{
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0,0,0,0.5); */
} 
.banner{
  position: relative;
  width: 100%;
  height: 70vh;
}
.first-banner{
  width: 100%;
  height: 100%;
}
.second-banner{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
.banner-curtain{
  // background: linear-gradient(-45deg, #ac32e4, #7918f2, #4801ff);
  background-color: rgba(51, 23, 122, 0.4);
  // opacity: 0.2;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
}
.banner-content {
  display: inline-block;
  text-align: center;
  max-width: 500px;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: var(--mainFont);
}
.banner-logo {
  font-size: 60px;
  color: var(--primaryColor);
  animation: slideFromRight 3s ease-in-out;
  margin: 0;
  color: var(--primaryColor);
}
.banner-logo span {
  font-size: 70px;
  background-color: var(--mainBlack);
  padding: 0 20px;
  color: white;
  letter-spacing: var(--mainSpacing);
}
.banner p {
  text-align: center;
  color: azure;
  // background-color: white;
  padding: 8px 0;
  margin-bottom: 0;
  font-size: 20px;
  line-height: var(--lineHeight);
  animation: slideFromLeft 3s ease-in-out;
  line-height: 30px;

  // backface-visibility: hidden;
  // animation-name: slideFromLeft;
  // animation-duration: 5s;
  // animation-iteration-count: infinite;
  // animation-timing-function: linear;
}
.banner p > span {
  font-weight: bold;
  color: var(--mainBlack);
  background-color: var(--primaryColor);
  padding: 0 7px;
}
.hiden-text{
  display: none;
}
@keyframes slideFromRight {
  0% {
    transform: translateX(1000px);
  }
  50% {
    transform: translateX(-200px);
  }
  75% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideFromLeft {
  0% {
    transform: translateX(-1000px);
  }
  50% {
    transform: translateX(200px);
  }
  75% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0);
  }
}
.sign-img{
  left: 0;
  top: 0;
  width: 10%;
  height:80%;
  position: absolute;
  display: inline;
}
.banner-text{
  position: absolute;
  top: -15px;
  font-size: 30px;
  text-align: left;
  width: 450px;
  /* font-weight: bold; */
  font-family: var(--mainFont);
  letter-spacing: var(--mainSpacing);
  line-height: 40px;
  left: 17px;
}
.banner-text span{
  font-weight: bolder;
}
.carousel .carousel-status {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  color: #fff;
}
.carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity=30);
  background: var(--primaryColor);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.carousel .control-next.control-arrow:before {
  border-left: 8px solid var(--primaryColor);
}
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid var(--primaryColor);
}
@media screen and (max-width: 415px) {
  .hero {
    /* background-image: none !important; */
    justify-content: center;
  }
  .banner-text{
    display: none;
  }
}
@media screen and (max-width: 992px){
  /* .hero-banner{
    display: none;
  } */
  .banner-text{
    font-size: 20px;
  }
}
