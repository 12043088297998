.all{
    /* background: linear-gradient(-180deg, #ac32e4, #7918f2, #4801ff); */
}
footer{
    background: linear-gradient(-180deg, #ac32e4, #7918f2, #4801ff);
    padding: var(--mainPadding);
    position: relative;
}
footer > .heading{
    margin: 20px;
    color: skyblue;
}
footer h1{
    color: white;
}
.contact-info, .footer-menu{
    flex: 1;
    text-align: center;
}
.contact-icons{
    color: var(--primaryColor);
    font-size: var(--iconFont);
    margin-right: 5px;
}
.contact-knowledge{
    display: inline-block;
    font-size: var(--secondTextFont);
    font-family: var(--mainFont);
    color: white;
    /* margin-right: 25px; */
    /* margin-bottom: 13px; */
    padding: var(--mainPadding);
}
.footer-container{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
}
.footer-menu a{
    display: block;
    text-decoration: none;
    color: white;
    font-size: var(--secondTextFont);
    font-family: var(--mainFont);
    margin-bottom: 15px;
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
}
.footer-menu a:hover{
    color: var(--primaryColor);
}
.copyright{
    text-align: center;
    font-size: 13px;
    color: white;
    font-family: var(--mainFont);
    letter-spacing: var(--mainSpacing);
    margin: 0;
}
.contact-icons-link{
    margin-right: 0.7rem;
    color: var(--primaryColor);
    font-size: var(--iconFont);
    transition: all 0.13s linear;
    padding: 5px;
}
.contact-icons-link:hover {
    color: var(--mainWhite);
    transform: translateY(-10%);
    cursor: pointer;
}
/* .services{
    padding: var(--mainPadding);
}
.service {
text-align: center;
margin-bottom: 3rem;
}
.service-icon {
color: var(--primaryColor);
display: inline-block;
font-size: 33px;
}
.service-title {
font-family: "Lato", sans-serif;
letter-spacing: var(--mainSpacing);
color: var(--mainWhite);
}
.service-text {
width: 80%;
margin-left: auto;
margin-right: auto;
line-height: 1.5;
font-family: "Lato", sans-serif;
letter-spacing: var(--mainSpacing);
color: var(--mainWhite);
}
@media screen and (min-width: 576px) {
.services-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.service {
    flex: 0 0 calc(50% - 1rem);
}
}
@media screen and (min-width: 992px) {
.service {
    flex: 0 0 calc(33.3333% - 1rem);
    margin-bottom: 0;
}
}
@media screen and (min-width: 1170px) {
.service {
    display: flex;
    text-align: left;
    margin-bottom: 0;
}
.service-icon {
    align-self: start;
}
.service-info {
    padding-left: 1rem;
    margin-bottom: 0.5rem;
}
.service-text {
    width: 90%;
    margin-left: 0;
    margin-right: 0;
}
} */