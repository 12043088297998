.featured-center {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0 2rem;
}
.flip-card {
  width: 230px;
  height: 210px;
  perspective: 1000px;
  margin-bottom: 2rem;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  // border: 1px solid orange;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
}

.flip-card-front img {
  width: 100%;
  height: 100%;
  // border-radius: 13px;
}
.flip-card-title{
  position: absolute;
  top: 67%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 15px;
  color: black;
  background-color:rgba(254, 237, 0, 0.9);
  padding: 4px 20px;
  letter-spacing: var(--mainSpacing);
}
.flip-card-back {
  color: white;
  transform: rotateY(180deg);
  cursor: pointer;
  opacity: 1;
}
.flip-card-back img{
  width: 100%;
  height: 100%;
}
.flip-card-curtain{
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #ac32e4, #7918f2, #4801ff);
  opacity: 0.6;
}
.flip-card-back-icon{
  font-size: 15px;
  position: absolute;
  top: 40%;
  color: white;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 13px;
  border-radius: 50%;
}
@media screen and (max-width: 420px) {
  // .featured-center{
  //   margin-top: 50px;
  // }
}
// .feature-card{
//   margin-bottom: 2rem;
//   transition: var(--mainTransition);
//   width: 230px;
//   height: 240px;
//   position: relative;
//   border-radius: 13px;
//   border: 1px solid orange;
// }
// .feature-card-button {
//   color: var(--mainBlack);
//   font-size: 16px;
//   border: 1px solid var(--primaryColor);
//   padding: 4px 8px;
//   background: linear-gradient(to right, var(--mainBlack) 50%, var(--primaryColor) 50%);
//   background-size: 200% 100%;
//   background-position: right bottom;
//   transition: all 0.2s linear;
//   font-weight: bold;
// }
// .feature-card:hover {
//   .feature-card-button{
//     border: 1px solid var(--mainBlack);
//     border-left: none;
//     color: white;
//     background-position: left bottom;
//   }
//   cursor: pointer;
//     z-index: 1;
//     box-shadow: var(--darkShadow);
// }
// .feature-card-img {
//     width: 100%;
//     height: 80%;
//     border-top-right-radius: 13px;
//     border-top-left-radius: 13px;
// }