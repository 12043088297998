.video-center{
    background-color: #edffff;
    margin-bottom: 70px;
}
.video-info {
    margin-top: 30px;
    padding-bottom: 17px;
}
.video-info p{
font-family: var(--mainFont);
letter-spacing: 0.5px;
line-height: 25px;
color: var(--darkGray);
}
.video-info h1{
font-family: var(--mainFont);
/* line-height: 25px; */
font-size: 50px;
font-weight: normal;
margin-top: 0;
}
.video-center-video {
    height: 299px;
    border-radius: 7px;
    box-shadow: -1px 4px 19px -3px rgba(0,0,0,0.8);
}
.video{
    width: 100%;
    height: 100%;
}
@media screen and (min-width: 992px) {
.video-center {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
}
.video-center-video {
    width: 530px;
}
.video-info {
    max-width: 600px;
    flex: 0 1 calc(50% - 2rem);
    margin-top: 0;
}
}