.catridges{
  position: relative;
}
.back-detail{
  position: absolute;
  font-size: 15px;
  left: 4rem;
  top: 2.3rem;
}
.catridges-center {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 40px;
  padding: var(--mainPadding);
}
.card {
  margin-bottom: 2rem;
  transition: var(--mainTransition);
  height: 260px;
  min-width: 227px;
  max-width: 227px;
  flex: 0 0 calc(23% - 4.5rem);
  border-radius: 13px;
  border: 1.5px solid orange;
}
.card-more{
  position: absolute;
  color: var(--mainBlack);
  top: 7%;
  left: -0.3%;
  font-size: 15px;
  border: 1px solid var(--primaryColor);
  padding: 4px 8px;
  background: linear-gradient(to right, var(--mainBlack) 50%, var(--primaryColor) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.2s linear;
  font-weight: bold;
}
.card:hover {
  .card-more{
    border: 1px solid var(--mainBlack);
    border-left: none;
    color: white;
    background-position: left bottom;
  }
  cursor: pointer;
  z-index: 1;
  box-shadow: var(--darkShadow);
}
.card-img-container {
  position: relative;
  width: 100%;
  height: 70%;
}
.card-img {
  width: 100%;
  height: 100%;
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
}
.card-footer-icon {
  position: absolute;
  color: var(--mainBlack);
  font-size: 13px;
  top: 85%;
  left: 100%;
}
.card-img-icon {
  position: absolute;
  color: var(--mainBlack);
  font-size: 30px;
  top: 7%;
  left: 0;
  font-size: 17px;
  border: 1px solid var(--primaryColor);
  padding: 5px 10px;
  background: linear-gradient(to right, var(--mainBlack) 50%, var(--primaryColor) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.2s linear;
  font-weight: bold;
}
.card-footer {
  text-align: center;
  padding-right: 18px;
  padding-left: 8px;
}
.card-title {
  font-family: var(--mainFont);
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
  font-size: 15px;
  margin: 0;
  color: var(--mainBlack);
}
.card-brand {
  color: var(--mainBlack);
  align-self: center;
  font-size: 13px;
  margin: 8px 0;
}
.card-model {
  display: none;
}
.card-price {
  color: var(--mainBlack);
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  position: relative;
  display: inline-block;
}
.card-menu {
margin: 20px 0 30px 0;
text-align: center;
}
.card-menu-btn {
border-radius: 3px;
font-family: var(--mainFont);
font-size: 15px;
padding: 5px 10px;
margin-right: 5px;
font-weight: bold;
border: var(--primaryColor) solid 2px;
background-color: var(--primaryColor);
color: var(--mainBlack);
transition: var(--mainTransition);
cursor: pointer;
}
.card-menu button:hover {
background-color: var(--mainBlack);
color: white;
border: 2px solid var(--mainBlack);
}

