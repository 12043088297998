.about {
  padding: var(--mainPadding);
  background: var(--mainWhite);
}
.subtitle-about{
  letter-spacing: var(--mainSpacing);
  font-family: var(--mainFont);
  font-size: 2rem;
  text-transform: capitalize;
  color: var(--mainBlack);
  margin-bottom: 0;
  margin-top: 0;
}
.about-info {
  margin-bottom: 1rem;
  color: var(--darkGray);
  flex: 0 0 calc(50% - 1rem);
}
.map {
  flex: 0 0 calc(50% - 1rem);
  height: 300px;
}
.map iframe {
  width: 100%;
  height: 100%;
}
.about-info p{
  font-family: var(--mainFont);
  letter-spacing: 0.5px;
  line-height: 25px;
}
@media screen and (min-width: 992px) {
  .about-center {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 33px;
  }
  .about-info {
    margin-bottom: 0;
  }
}