// @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
html{
    --primaryColor: #feed00;
    --mainBlack: #2b2a29;
    --mainWhite: #fff;
    --mainGray: #bdbaba;
    --darkGray: #808291;
    --mainTransition: all 0.13s linear;
    --mainSpacing: 0.103rem;
    --iconFont: 1.3rem;
    --secondTextFont: 17px;
    --mainPadding: 1rem 2rem;
    --lightShadow: 2px 5px 3px 4px rgba(0, 0, 0, 0.25);
    --darkShadow: 4px 10px 5px 6px rgba(0, 0, 0, 0.5);
    --mainFont: 'Roboto', sans-serif;

    --primary-color: #6CD9CE;
  --secondary-color: #D93BA1;
  --complimentary-color: #2E2473;
}
body{
    background-color: var(--mainWhite);
    position: relative;
}
a{
    text-decoration: none;
}
.title{
    letter-spacing: var(--mainSpacing);
    font-family: "Lato", sans-serif;
    font-size: 2rem;
    text-transform: capitalize;
    text-align: center;
    color: var(--mainWhite);
    margin-bottom: 0;
}
.subtitle{
    letter-spacing: var(--mainSpacing);
    font-family: var(--mainFont);
    font-size: 2rem;
    text-align: center;
    color: var(--mainBlack);
    margin-bottom: 0;
}
.under-line {
    border: 2px solid var(--primaryColor);
    margin: 10px auto 33px auto;
    width: 130px;
    transition: width 2s ease, border 2s ease;
}
.up{
    position: fixed;
    font-size: 30px;
    color: var(--mainBlack);
    left: 97%;
    top: 85%;
    cursor: pointer;
    animation: upDown 1s ease-in-out infinite;
    z-index:10;
}
@keyframes upDown {
    0% {
      transform: translateY(-3px);
    }
    50% {
      transform: translateY(6px);
    }
    100% {
      transform: translateY(-3px);
    }
}
@media screen and (max-width: 670px) {
    .up{
        left: 95%;
    }
}
// .fixed {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     background-color: rgba($color: #2b2a29, $alpha: 0.9);
//     transition: var(--mainTransition);
//     box-shadow: var(--lightShadow);
//     z-index: 3;
//   }


@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};   
    @media (prefers-reduced-motion) {
      .animated {
        -webkit-animation: unset !important;
        animation: unset !important;
        -webkit-transition: none !important;
        transition: none !important;
      }
    }   
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin transform($value){
    -webkit-transform: $value;
    -moz-transform: $value;
    -ms-transform: $value;
    -o-transform: $value;
    transform: $value;
}

.doing{
transform: rotate(-35deg);
display: block;
position: absolute;
top: 0;
left: -90px;
margin-top: 25px;
text-align: center;
// background-color: #15748b;
// width: 300px;
color: #fff;
padding: 5px 0px;
}

.will-fadeIn{
display: block;
margin: 20px auto;
height: 250px;
// background-color: #b7f7f7;
&:nth-child(even) {
    background-color: #edffff;
//   background-color: #ffeded
}
&:first-child{
    height: auto;
//   background-color: #edffff;
}
}

.fadeInRight {
    @include animation('fadeInRight .3s ease .2s both');

}

.fadeInLeft {
    @include animation('fadeInLeft .3s ease .2s both');
}

@include keyframes(fadeInRight) {
    from {
        opacity: 0;
        @include transform( translate3d(100%, 0, 0));
    }

    to {
        opacity: 1;
        @include transform( translate3d(0, 0, 0));
    }
}

@include keyframes(fadeInLeft) {
    from {
        opacity: 0;
        @include transform( translate3d(-100%, 0, 0));
    }

    to {
        opacity: 1;
        @include transform( translate3d(0, 0, 0));
    }
}
.hideme
{
  opacity:0;
}